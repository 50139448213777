.link-item {
  align-items: center;
  text-align: left;
  padding: 5px;
  font-size: 1em;
}

@media only screen and (max-width: 890px) {
  .link-item {
    font-size: 0.8em;
  }
}

hr.solid {
  border-top: 1px solid;
  width: 90%;
}

.tutorial-button-container {
  position: fixed;
  bottom: 20px;
  right: 0px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.sdg-icon {
  height: 50px;
  width: 50px;
}

.sdg-icon-mobile {
  height: 55px;
  width: 55px;
}

@media only screen and (max-width: 1200px) {
  .sdg-icon {
    height: 45px;
    width: 45px;
  }
}

@media only screen and (max-width: 1080px) {
  .sdg-icon {
    height: 40px;
    width: 40px;
  }
}

@media only screen and (max-width: 990px) {
  .sdg-icon {
    height: 35px;
    width: 35px;
  }
}

@media only screen and (max-width: 890px) {
  .sdg-icon {
    height: 30px;
    width: 30px;
  }
}

.network-canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.network-svg {
  position: absolute;
  top: 0px;
  left: 0px;
}

.line {
  margin: 0 4px;
  height: 2px;
  background-size: 100% 2px;
  background-repeat: no-repeat;
  width: 20%;
}